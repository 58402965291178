<template>
   <div>
      <b-navbar
         toggleable="sm"
         type="dark"
         variant="success"
         fixed="top"
         class="header"
      >
         <div class="container">
            <b-navbar-brand href="#">PSB Ponpes Baiturrahman</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
               <!-- Right aligned nav items -->
               <b-navbar-nav class="ml-4">
                  <li class="nav-item">
                     <router-link class="nav-link" to="/">Home</router-link>
                  </li>
                  <li class="nav-item">
                     <router-link class="nav-link" to="/brosur"
                        >Brosur & Panduan</router-link
                     >
                  </li>
               </b-navbar-nav>
               <b-navbar-nav class="ml-auto">
                  <li class="nav-item">
                     <router-link
                        class="nav-link"
                        to="/admin"
                        v-show="
                           this.$globalData.IsAdmin &&
                              this.$globalData.userName.toUpperCase() == 'ADMIN'
                        "
                        >Admin Area</router-link
                     >
                  </li>
                  <li class="nav-item">
                     <b-link @click="setlogin()" class="nav-link">
                        Login:<b>
                           {{
                              this.$globalData.userName == ""
                                 ? "none"
                                 : this.$globalData.userName
                           }}</b
                        >
                     </b-link>
                  </li>
               </b-navbar-nav>
            </b-collapse>
         </div>
      </b-navbar>
   </div>
</template>

<script>
//import axios from "axios";

export default {
   name: "Navbar",
   data() {
      return {};
   },
   methods: {
      setlogin() {
         this.$globalData.showLogin = !this.$globalData.showLogin;
      },
   },
   mounted() {},
};
</script>

<style></style>
