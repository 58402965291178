<template>
   <div class="container">
      <div class="card shadow">
         <b-tabs
            card
            shadow
            active-nav-item-class="font-weight-bold text-uppercase"
            active-tab-class="text"
         >
            <b-tab title="Brosur" active class="tab primary">
               <!-- <img src="../assets/images/logo-psb.jpg" width="100%" /> -->
               <img src="../assets/images/psb-1.jpg" width="100%" />
               <!-- <b-img src="dokumen/brosur-1.jpg" width="900px" /> -->
               <img
                  src="../assets/images/psb-2.jpg"
                  width="100%"
                  class="mt-2"
               />
               <!-- <b-img src="dokumen/brosur-2.jpg" width="900px" class="mt-2" /> -->
            </b-tab>
            <b-tab title="Panduan" class="tab primary">
               <!-- <object data="dokumen/panduan.pdf" width="100%" height="600" /> -->
               <img
                  src="../assets/images/panduan.jpg"
                  width="100%"
                  height="600"
               />
            </b-tab>
         </b-tabs>
      </div>

      <!-- <Footer /> -->
   </div>
</template>

<script>
// import Property from "@/components/property.vue";

// import Footer from "@/components/footer.vue";
// import axios from "axios";
// import Navbar from "./navbar.vue";

//import { VueTabs, VTab } from "vue-nav-tabs";
//import "vue-nav-tabs/themes/vue-tabs.css";

export default {
   name: "Brosur",
   components: {},
   data() {
      return {};
   },
   mounted() {},
   methods: {},
};
</script>

<style>
#app {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: #2c3e50;
   margin-top: 60px;
}
#stickys {
   background-color: rgb(251, 253, 251);
   position: fixed;
   z-index: 99;
   width: 100%;
   margin-top: 6px;
}
</style>
