<template>
   <div class="float">
      <!-- {{ records }} -->
      <!-- <b-card class="float" header="Pasfoto"> -->

      Pasfoto:
      <b-form-file
         id="file1"
         name="file1"
         v-model="file1"
         size="sm"
         @change="changeFile($event, 1)"
         accept="image/*,application/pdf"
      />

      <b-img
         thumbnailpa
         :src="records.pasfoto"
         width="150"
         height="150"
         alt="image"
         class="ml-3"
      ></b-img>
      <b-button
         size="sm"
         @click="uploadfile('file1', 'pasfoto')"
         class="ml-2  mt-2"
         >Upload</b-button
      >
      <!-- </b-card> -->
      <hr />
      Kartu Keluarga:
      <b-form-file
         id="file2"
         v-model="file2"
         @change="changeFile($event, 2)"
         accept="image/*,application/pdf"
      />
      <b-img
         thumbnail
         :src="records.kk"
         width="150"
         height="300"
         alt="Fluid image"
      ></b-img>
      <b-button size="sm" @click="uploadfile('file2', 'kk')" class="ml-2 mt-2"
         >Upload</b-button
      >
      <hr />
      Akte Kelahiran Calon Santri:
      <b-form-file
         id="file3"
         name="file3"
         v-model="file3"
         @change="changeFile($event, 3)"
         accept="image/*,application/pdf"
      />
      <!-- Akte:{{ records.akte }}, {{ records.akte.indexOf("null") }} -->
      <object
         :data="records.akte"
         width="100%"
         height="300"
         v-show="records.akte.indexOf('null') == -1"
      />
      <b-img
         thumbnail
         :src="records.akte"
         width="150"
         height="300"
         alt="Fluid image"
         class="ml-3"
      ></b-img>
      <b-button size="sm" @click="uploadfile('file3', 'akte')" class="ml-2 mt-2"
         >Upload</b-button
      >
      <hr />
      KTP Ayah:
      <b-form-file
         id="file4"
         name="file4"
         v-model="file4"
         @change="changeFile($event, 4)"
         accept="image/*,application/pdf"
      />
      <b-img
         thumbnail
         :src="records.ktpa"
         width="150"
         height="300"
         alt="Fluid image"
         class="ml-3"
      ></b-img>
      <b-button size="sm" @click="uploadfile('file4', 'ktpa')" class="ml-2 mt-2"
         >Upload</b-button
      >
      <hr />
      KTP Ibu:
      <b-form-file
         id="file5"
         name="file5"
         v-model="file5"
         @change="changeFile($event, 5)"
         accept="image/*,application/pdf"
      />
      <b-img
         thumbnail
         :src="records.ktpi"
         width="150"
         height="300"
         alt="Fluid image"
         class="ml-3"
      ></b-img>
      <b-button size="sm" @click="uploadfile('file5', 'ktpi')" class="ml-2 mt-2"
         >Upload</b-button
      >
      <!-- <hr />
      Keterangan Sehat:
      <b-form-file
         id="file6"
         name="file6"
         v-model="file6"
         @change="changeFile($event, 6)"
         accept="image/*,application/pdf"
      />
      <b-img
         thumbnail
         :src="records.ketsehat"
         width="150"
         height="300"
         alt="Fluid image"
         class="ml-3"
      ></b-img> -->
      <b-button
         size="sm"
         @click="uploadfile('file6', 'ketsehat')"
         class="ml-2 mt-2"
         >Upload</b-button
      >
      <hr />
      <!-- <object :data="blob" width="100%" height="700" /> -->
      <!-- <b-img
      thumbnail
      :src="blob"
      width="300"
      height="400"
      alt="Fluid image"
    ></b-img> -->
   </div>
</template>

<script>
// import Property from "@/components/property.vue";

// import Footer from "@/components/footer.vue";
import axios from "axios";
// import Navbar from "./navbar.vue";

//import { VueTabs, VTab } from "vue-nav-tabs";
//import "vue-nav-tabs/themes/vue-tabs.css";

export default {
   name: "DokumenUpload",
   props: ["userid", "records"],
   components: {},
   data() {
      return {
         file1: null,
         file2: null,
         file3: null,
         file4: null,
         file5: null,
         file6: null,
         blob: [],
      };
   },
   watch: {
      records(val) {
         console.log("val watch", val);
         this.records.pasfoto = this.$appUrl + val.pasfoto;
         this.records.kk = this.$appUrl + val.kk;
         this.records.akte = this.$appUrl + val.akte;
         this.records.ktpa = this.$appUrl + val.ktpa;
         this.records.ktpi = this.$appUrl + val.ktpi;
         this.records.ketsehat = this.$appUrl + val.ketsehat;
      },
   },
   mounted() {
      this.blob[1] = "http://localhost:8800/psb/" + this.records.pasfoto;
      //  this.blob[1] = this.$appUrl + "uploads/pasfoto-".png";
      this.blob[2] = "http://localhost:8800/psb/uploads/noimage.png";
      this.blob[3] = "http://localhost:8800/psb/uploads/noimage.png";
      this.blob[4] = "http://localhost:8800/psb/uploads/noimage.png";
      this.blob[5] = "http://localhost:8800/psb/uploads/noimage.png";
      this.blob[6] = "http://localhost:8800/psb/uploads/noimage.png";
   },
   methods: {
      uploadfile(nf, jenis) {
         var config = {
            onUploadProgress: function(progressEvent) {
               console.log("progress event", progressEvent);
               var percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
               );
               console.log("progress", percentCompleted);
            },
            headers: {
               "Content-Type": "multipart/form-data",
            },
         };
         var data = new FormData();
         data.append("username", this.userid);
         data.append("jenis", jenis);

         data.append("file", document.getElementById(nf).files[0]);
         axios
            .post(this.$appUrl + "upload.php", data, config)
            .then((res) => {
               console.log("res", res.data);
               this.$toast.success(res.data, {
                  type: "success",
                  position: "top-right",
                  duration: 3000,
                  dismissible: true,
               }); //   output.className = 'container';
               //   output.innerHTML = res.data;
            })
            .catch(function(err) {
               console.log("error", err);
               //   output.className = 'container text-danger';
               //   output.innerHTML = err.message;
            });
      },
      changeFile(event, index) {
         console.log("changefile event", event);
         console.log("changefile index", index);
         // console.log("changefile index2", index2);
         const file = event.target.files[0];
         if (index == 1) {
            this.records.pasfoto = URL.createObjectURL(file);
         } else if (index == 2) {
            this.records.kk = URL.createObjectURL(file);
         } else if (index == 3) {
            this.records.akte = URL.createObjectURL(file);
         } else if (index == 4) {
            this.records.ktpa = URL.createObjectURL(file);
         } else if (index == 5) {
            this.records.ktpi = URL.createObjectURL(file);
         } else {
            this.records.ketsehat = URL.createObjectURL(file);
         }

         // alert(file.name);
         console.log(this.blob[index]);
      },
   },
};
</script>

<style>
#app {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: #2c3e50;
   margin-top: 60px;
}
#stickys {
   background-color: rgb(251, 253, 251);
   position: fixed;
   z-index: 99;
   width: 100%;
   margin-top: 6px;
}
</style>
