<template>
   <b-row>
      <b-col cols="auto" v-show="this.$globalData.showLogin">
         <div class="col-md-6">
            <img src="../assets/images/logo-psb.jpg" width="70%" />
         </div>
         <b-card header="Login Area" header-tag="header" class="mt-3">
            <b-card-text align="left">
               <!-- <form> -->
               Username:
               <b-form-input
                  class="form-control mb-2"
                  v-model="username"
                  @keyup="keyupMode()"
                  required
                  size="sm"
               />
               Password:
               <b-form-input
                  id="mypassword"
                  type="password"
                  class="form-control mb-2"
                  v-model="password"
                  required
                  autocomplete="on"
                  size="sm"
               />
               <div v-show="shownew == 'true'">
                  <b-row>
                     <b-form-radio
                        v-model="selected"
                        name="some-radios"
                        value="P"
                        size="sm"
                        class="ml-3 mt-2"
                        >SMP</b-form-radio
                     >
                     <b-form-radio
                        v-model="selected"
                        name="some-radios"
                        value="A"
                        size="sm"
                        class="ml-3 mb-4 mt-2"
                        >SMA</b-form-radio
                     >
                  </b-row>
                  No.HP/WA:
                  <b-form-input
                     id="nohp"
                     class="form-control mb-4"
                     placeholder="081"
                     v-model="nohp"
                     autocomplete="on"
                     size="sm"
                  />
               </div>
               <!-- </form> -->
               <b-row>
                  <b-link class="btn btn-success ml-3" @click="gologin()"
                     ><b-icon-box-arrow-in-right></b-icon-box-arrow-in-right>
                     {{ shownew == "true" ? "Daftar" : "Login" }}</b-link
                  >
                  <b-form-checkbox
                     id="checkbox-1"
                     v-show="false"
                     v-model="shownew"
                     value="true"
                     unchecked-value="false"
                     class="mt-3 ml-2"
                     size="sm"
                  >
                     <!-- @change="showtabs = !showtabs" -->
                     Buat User
                  </b-form-checkbox>
               </b-row>
               <div style="font-size:14px" class="mt-3 ml-2">
                  Jika belum memiliki user, register
                  <b-link v-b-modal.modal-renewal class="mt-4">disini</b-link>
               </div>

               <!-- <b-link @click="shownew = true">Daftar</b-link> -->
            </b-card-text>
            <!-- <b-button href="#" variant="primary">Go somewhere</b-button> -->
         </b-card>
      </b-col>
      <!-- <b-col v-show="this.$globalData.showLogin && this.os == 'Windows'"> -->
      <div class="col-md" v-show="this.$globalData.showLogin">
         <div>
            <img src="../assets/images/psb.jpg" width="100%" />
         </div>
      </div>

      <!-- OS {{ os }} -->
      <b-col v-show="!this.$globalData.showLogin">
         <!-- <div class="card shadow" :style="{ height: myheight }"> -->
         <div class="card shadow" style="height: auto">
            <!-- <div v-show="!showtabs || os == 'Windows'">
          <img src="../assets/images/psb.jpg" width="100%" />
        </div> -->
            <div v-show="showtabs">
               <b
                  ><h4 class="bg-dark text-light">
                     {{
                        issmp
                           ? "Pendaftaran SMP Gel " + this.$globalData.Gelombang
                           : "Pendaftaran SMA Gel " + this.$globalData.Gelombang
                     }}
                     {{ form.isalumni == "0" ? "" : "  Alumni" }}
                  </h4></b
               >
            </div>
            <b-tabs
               v-show="showtabs"
               header="Testing"
               card
               shadow
               active-nav-item-class="font-weight-bold text-uppercase"
               active-tab-class="text"
            >
               <b-tab title="Data Siswa" active>
                  <b-form @submit="onSubmit" @reset="onReset">
                     <b-card
                        class="text-left mt-3"
                        header="Data Siswa"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        style="max-width: 50rem"
                     >
                        <div
                           style="display: flex; justify-content: flex-end; font-size:12px"
                        >
                           <a
                              href="javascript:void(0)"
                              @click="getalumni()"
                              v-show="!issmp"
                           >
                              Alumni BR klik disini
                           </a>
                        </div>
                        <!-- Issmp: {{ issmp }} -->
                        <div class="col w-100 control-label">
                           Nama:
                           <b-form-input
                              id="input-1"
                              v-model="form.nama"
                              type="text"
                              required
                              size="sm"
                           ></b-form-input>
                        </div>
                        <b-row>
                           <div class="ml-4 mt-3 mb-2">Jenis Kelamin:</div>
                           <b-form-radio
                              v-model="form.jeniskel"
                              value="L"
                              size="sm"
                              class="ml-4 mt-3"
                           >
                              Laki-laki</b-form-radio
                           >
                           <b-form-radio
                              v-model="form.jeniskel"
                              class="ml-4 mt-3 mb-2"
                              value="P"
                              size="sm"
                              >Perempuan</b-form-radio
                           >
                        </b-row>
                        <b-col cols="auto mt-2" v-show="false">
                           <b-form-group
                              label="Jenis Kelamin:"
                              class="control-label"
                           >
                              <b-form-radio-group
                                 v-model="form.jeniskel"
                                 :options="kelamin"
                              ></b-form-radio-group
                           ></b-form-group>
                        </b-col>

                        <b-row class="ml-0">
                           <!-- <b-col cols="auto" -->
                           <b-col cols="md-6"
                              ><b-form-group
                                 label="Tempat Lahir:"
                                 class="control-label"
                                 ><b-form-input
                                    v-model="form.tempatlahir"
                                    size="sm"
                                    required/></b-form-group
                           ></b-col>
                           <!-- <b-col cols="auto" -->
                           <b-col cols="md-6"
                              ><b-form-group
                                 label="Tgl Lahir:"
                                 class="control-label"
                              >
                                 <b-form-input
                                    type="date"
                                    size="sm"
                                    v-model="form.tgllahir"
                                    @input="umur"
                                    required/></b-form-group
                           ></b-col>
                        </b-row>

                        <b-row class="ml-0">
                           <!-- <b-col cols="auto" -->
                           <b-col cols="md-6"
                              ><b-form-group label="NISN:" class="control-label"
                                 ><b-form-input
                                    v-model="form.nisn"
                                    size="sm"/></b-form-group
                           ></b-col>
                           <!-- <b-col cols="auto" -->
                           <b-col cols="md-6"
                              ><b-form-group
                                 label="NIK:(Nomor Induk Kependudukan)"
                                 class="control-label"
                              >
                                 <b-form-input
                                    v-model="form.nik"
                                    size="sm"
                                    required/></b-form-group
                           ></b-col>
                        </b-row>
                        <div class="col w-100 control-label mt-3">
                           Alamat:
                           <b-form-textarea
                              id="input-5"
                              v-model="form.alamat"
                              @change="console.log('hello')"
                              type="text"
                              size="sm"
                              required
                           ></b-form-textarea>
                        </div>
                        <div class="col w-auto control-label mt-2">
                           Kota:
                           <b-form-input
                              id="jalan"
                              v-model="form.kota"
                              size="sm"
                              required
                           ></b-form-input>
                        </div>
                        <div class="col w-auto control-label mt-3">
                           No.HP/WA:
                           <b-form-input
                              id="jalanhp"
                              v-model="form.nohp"
                              size="sm"
                              required
                           ></b-form-input>
                        </div>

                        <div class="col w-100 control-label mt-3">
                           Asal Sekolah:
                           <b-form-input
                              id="jalansek"
                              v-model="form.asalsekolah"
                              size="sm"
                              required
                           ></b-form-input>
                        </div>
                        <!-- issmp: {{ form.jenjang }} -->
                        <b-row class="ml-3 mt-3 control-label">
                           <div class="col-md-6" v-show="!issmp">
                              <b-form-group
                                 label="Pilihan Jurusan:"
                                 class="control-label"
                              >
                                 <b-form-radio-group
                                    v-model="form.pilihanjurusan"
                                    label="Pilihan Jurusan"
                                 >
                                    <b-form-radio value="ipa">IPA</b-form-radio>
                                    <b-form-radio value="ips">IPS</b-form-radio>
                                 </b-form-radio-group>
                              </b-form-group>
                           </div>
                           <b-col cols="md-6">
                              <b-form-group
                                 label="Warga Negara:"
                                 class="control-label"
                              >
                                 <b-row class="ml-0">
                                    <b-form-radio
                                       v-model="form.warganegara"
                                       name="some-radios"
                                       value="wni"
                                    >
                                       WNI</b-form-radio
                                    >
                                    <b-form-radio
                                       v-model="form.warganegara"
                                       class="ml-4"
                                       value="wna"
                                       size="sm"
                                       >WNA</b-form-radio
                                    >
                                 </b-row>
                              </b-form-group></b-col
                           >
                        </b-row>
                        <b-row class="ml-0 mt-2 control-label">
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Tinggi Badan(cm):"
                                 class="control-label"
                                 ><b-form-input
                                    v-model="form.tinggi"
                                    type="number"
                                    size="sm"
                                    required/></b-form-group
                           ></b-col>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Berat Badan(kg):"
                                 class="control-label"
                              >
                                 <b-form-input
                                    v-model="form.berat"
                                    type="number"
                                    size="sm"
                                    required/></b-form-group
                           ></b-col>
                        </b-row>
                        <b-row class="ml-0 mt-2 control-label">
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Anak ke:"
                                 class="control-label"
                                 ><b-form-input
                                    v-model="form.anakke"
                                    type="number"
                                    size="sm"
                                    required/></b-form-group
                           ></b-col>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Dari:"
                                 class="control-label"
                              >
                                 <b-form-input
                                    v-model="form.dari"
                                    type="number"
                                    size="sm"
                                    required/></b-form-group
                           ></b-col>
                        </b-row>
                     </b-card>
                     <div class="col w-75 control-label mt-3">
                        <!-- <b-form-group id="input-group-4">

                        </b-form-group> -->
                        <b
                           >Mengetahui Informasi Pesantren Baiturrahman dari:
                        </b>
                        <b-form-select
                           v-model="form.informasi"
                           size="sm"
                           :options="informasi"
                           required
                        />

                        <b>Daftar Masuk Ke Baiturrahman Karena: </b>
                        <b-form-select
                           v-model="form.motivasi"
                           size="sm"
                           :options="motivasi"
                           required
                        />
                        <hr />
                        <b>NOTES:</b>
                        <b-form-checkbox
                           id="checkbox-x"
                           v-model="form.checked"
                           name="checkbox-x"
                           value="true"
                           unchecked-value="false"
                        >
                           Dengan ini saya menyatakan bahwa data yang saya
                           isikan di Formulir ini adalah Benar adanya sesuai
                           dengan bukti-bukti yang ada
                        </b-form-checkbox>

                        <b-button
                           class="mt-3"
                           type="submit"
                           variant="primary"
                           :disabled="!form.checked"
                           >Submit Data</b-button
                        >
                     </div>
                  </b-form>
               </b-tab>
               <b-tab title="Data Orang Tua/Wali">
                  <b-form @submit="onSubmit2">
                     <b-card
                        header="Data Ayah"
                        class="text-left mt-3"
                        style="max-width: 50rem"
                     >
                        <b-col cols="auto">
                           <b-form-group label="" class="control-label">
                              <b-row>
                                 <b-form-radio
                                    v-model="form2.jenisayah"
                                    name="some-radios-a"
                                    value="kandung"
                                    size="sm"
                                 >
                                    Ayah Kandung</b-form-radio
                                 >
                                 <b-form-radio
                                    v-model="form2.jenisayah"
                                    name="some-radios-a"
                                    class="ml-4"
                                    value="wali"
                                    size="sm"
                                    >Wali</b-form-radio
                                 >
                              </b-row>
                           </b-form-group>
                        </b-col>

                        <b-form-group
                           class="control-label"
                           id="input-group-1"
                           label="Nama Ayah/Wali"
                           label-for="input-1"
                        >
                           <b-form-input
                              id="input-12"
                              v-model="form2.namaayah"
                              required
                              size="sm"
                           ></b-form-input>
                        </b-form-group>
                        <b-row>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Tempat Lahir:"
                                 class="control-label"
                                 ><b-form-input
                                    v-model="form2.tmplahirayah"
                                    size="sm"
                                    required/></b-form-group
                           ></b-col>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Tanggal Lahir:"
                                 class="control-label"
                              >
                                 <b-form-input
                                    type="date"
                                    size="sm"
                                    v-model="form2.tgllahirayah"
                                    required/></b-form-group
                           ></b-col>
                        </b-row>

                        <b-row>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Pekerjaan:"
                                 class="control-label"
                                 ><b-form-select
                                    v-model="form2.pekerjaanayah"
                                    :options="pekerjaan"
                                    size="sm"
                                    required/></b-form-group
                           ></b-col>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Penghasilan:"
                                 class="control-label"
                              >
                                 <b-form-select
                                    :options="pendapatan"
                                    size="sm"
                                    v-model="form2.penghasilanayah"
                                    required/></b-form-group
                           ></b-col>
                        </b-row>
                        <b-row>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Pendidikan"
                                 class="control-label"
                                 ><b-form-select
                                    v-model="form2.pendidikanayah"
                                    size="sm"
                                    :options="pendidikan"
                                    required/></b-form-group
                           ></b-col>
                        </b-row>

                        <b-form-group
                           class="control-label"
                           id="input-group-13"
                           label="Alamat:"
                           label-for="input-13"
                        >
                           <b-form-textarea
                              id="input-13"
                              v-model="form2.alamatayah"
                              type="text"
                              size="sm"
                              required
                           ></b-form-textarea>
                        </b-form-group>
                        <b-row>
                           <b-col cols="auto">
                              Kota:
                              <b-form-input
                                 id="jalanx"
                                 v-model="form2.kotaayah"
                                 required
                                 size="sm"
                              ></b-form-input>
                           </b-col>
                        </b-row>
                     </b-card>
                     <b-card header="Data IBU" class="text-left mt-2">
                        <b-col class="mt-3">
                           <!-- <b-form-group label="Data IBU:" class="control-label mt-2 ml-0"> -->
                           <b-row>
                              <b-form-radio
                                 v-model="form2.jenisibu"
                                 name="some-radios-i"
                                 value="kandung"
                                 size="sm"
                              >
                                 Ibu Kandung</b-form-radio
                              >
                              <b-form-radio
                                 v-model="form2.jenisibu"
                                 name="some-radios-i"
                                 class="ml-4"
                                 value="wali"
                                 size="sm"
                                 >Wali</b-form-radio
                              >
                           </b-row>
                           <!-- </b-form-group> -->
                        </b-col>

                        <b-form-group
                           class="control-label mt-2"
                           id="input-group-14"
                           label="Nama Ibu"
                           label-for="input-14"
                        >
                           <b-form-input
                              id="input-14"
                              v-model="form2.namaibu"
                              required
                              size="sm"
                           ></b-form-input>
                        </b-form-group>
                        <b-row>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Tempat Lahir:"
                                 class="control-label"
                                 ><b-form-input
                                    v-model="form2.tmplahiribu"
                                    size="sm"
                                    required/></b-form-group
                           ></b-col>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Tanggal Lahir:"
                                 class="control-label"
                              >
                                 <b-form-input
                                    type="date"
                                    size="sm"
                                    v-model="form2.tgllahiribu"
                                    required/></b-form-group
                           ></b-col>
                        </b-row>

                        <b-row>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Pekerjaan:"
                                 class="control-label"
                                 ><b-form-select
                                    v-model="form2.pekerjaanibu"
                                    size="sm"
                                    :options="pekerjaan"
                                    required/></b-form-group
                           ></b-col>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Penghasilan:"
                                 class="control-label"
                              >
                                 <b-form-select
                                    size="sm"
                                    :options="pendapatan"
                                    v-model="form2.penghasilanibu"
                                    required/></b-form-group
                           ></b-col>
                        </b-row>
                        <b-row>
                           <b-col cols="auto"
                              ><b-form-group
                                 label="Pendidikan"
                                 class="control-label"
                                 ><b-form-select
                                    v-model="form2.pendidikanibu"
                                    size="sm"
                                    :options="pendidikan"
                                    required/></b-form-group
                           ></b-col>
                        </b-row>
                     </b-card>

                     <b-button class="mt-3" type="submit" variant="primary"
                        >Submit Data</b-button
                     >
                     <!-- <b-button type="submit" variant="primary">Submit Data</b-button> -->

                     <!-- <Datasukses class="mt-3" /> -->
                  </b-form>
               </b-tab>
               <b-tab title="Dokumen Upload">
                  <DokumenUpload :userid="username" :records="records" />
               </b-tab>
               <b-tab title="Kartu Peserta" :disabled="status != '2'">
                  <!-- <b-tab title="Kartu Peserta"> -->
                  <Kartu :nopeserta="nopeserta" :nama="form.nama" :url="url" />
               </b-tab>
               <b-tab title="Informasi" class="tab primary">
                  <p class="mt-3 mb-6" v-html="messages" align="left"></p>
                  <br /><br />
                  <p class="mt-6" style="font-size:12px">
                     Jika ada hal yang perlu ditanyakan bisa WA ke
                     <b>0821-1638-6662</b>
                  </p></b-tab
               >
            </b-tabs>
         </div>
      </b-col>
      <div>
         <!-- <b-link v-b-modal.modal-scrollable>Daftar Alumni SMP</b-link> -->
         <b-modal
            id="modal-scrollable"
            scrollable
            :ok-only="true"
            title="Daftar Alumni BR"
         >
            <b-table
               striped
               hover
               :items="listalumni"
               small
               @row-clicked="pilihalumni"
            ></b-table>
         </b-modal>
      </div>
      <b-modal
         id="modal-renewal"
         :ok-only="true"
         hide-header
         hide-footer
         size="sm"
         no-close-on-backdrop
         shadow
      >
         <div class="login-logo text-center">
            <a href="javascript:void(0)" style="font-size:16px"
               ><b>Registrasi</b> Akun</a
            >
         </div>
         <div class="text-left">
            <center>
               <img
                  width="120px"
                  src="https://newpsb.baiturrahman.com/img/logo-psb.f811bf93.jpg"
               />
            </center>
            <br />
            <b-form @submit="onsubmitregister">
               <b-form-radio-group
                  v-model="register.issmp"
                  size="sm"
                  :options="[
                     { value: 'P', text: 'SMP' },
                     { value: 'A', text: 'SMA' },
                  ]"
                  class="d-flex justify-content-around"
               />
               <div class="inner-addon left-addon mt-2">
                  <b-icon-person-check class="bi bi-pepole" />
                  <b-form-input
                     type="text"
                     placeholder="Nama Lengkap"
                     size="sm"
                     v-model="register.namalengkap"
                     required
                  />
               </div>
               <div class="inner-addon left-addon mt-2">
                  <b-icon-whatsapp class="bi bi-pepole" />
                  <b-form-input
                     type="text"
                     placeholder="Nomor WA (0815....)"
                     size="sm"
                     v-model="register.nowa"
                     required
                  />
               </div>
               <div class="inner-addon left-addon mt-2">
                  <b-icon-envelope class="bi bi-pepole" />
                  <b-form-input
                     type="text"
                     placeholder="Alamat email"
                     size="sm"
                     v-model="register.email"
                  />
               </div>
               <b-form-checkbox
                  size="sm"
                  class="mt-2"
                  v-model="register.alumni"
                  v-show="false"
                  >Alumni Baiturrahman</b-form-checkbox
               >

               <div class="inner-addon left-addon mt-3">
                  <b-icon-person-lines-fill class="bi bi-pepole" />
                  <b-form-input
                     type="text"
                     placeholder="Username"
                     size="sm"
                     v-model="register.userid"
                     required
                  />
                  <em style="font-size:12px">*Tidak mengandung sepasi</em>
               </div>
               <div class="inner-addon left-addon mt-2">
                  <b-icon-key class="bi bi-pepole" />
                  <b-form-input
                     type="password"
                     placeholder="Password"
                     v-model="register.password"
                     size="sm"
                     required
                  />
                  <em style="font-size:12px">*max 8 character</em>
               </div>

               <!-- /.col -->
               <b-button
                  variant="outline-success"
                  type="submit"
                  class="mt-3"
                  size="sm"
               >
                  Register
               </b-button>
               <a
                  class="float-right mt-4"
                  style="cursor:pointer"
                  @click="$bvModal.hide('modal-renewal')"
                  >x</a
               >
            </b-form>
         </div>
      </b-modal>
   </b-row>
</template>

<script>
// virtual acc SMP 7216772223002100  (mulai dari 100)
// virtual acc SMA 7216772223002300  (mulai dari 300)

import axios from "axios";
// import { stringify } from "querystring";
import DokumenUpload from "../components/dokupload.vue";
import Kartu from "../components/kartu.vue";

export default {
   name: "Property",
   components: { DokumenUpload, Kartu },
   data() {
      return {
         myheight: "1510px",
         nopeserta: "",
         os: "",
         url: "",
         status: "",
         issmp: true,
         records: [],
         nova: "",
         selected: "P",
         username: "",
         password: "",
         nohp: "",
         showtabs: false,
         shownew: false,
         IsDisable: false,
         IsNew: "NEW",
         formshow: false,
         filter: "",
         file: null,
         blob: [],
         blob1: null,
         blob2: null,
         blob3: null,
         blob4: null,
         blob5: null,
         testform: [{ nama: "" }, { no_hp: "" }, { identify: 0 }],
         pekerjaan: [
            { text: "1. PNS/ASN", value: "1" },
            { text: "2. TNI/POLRI", value: "2" },
            { text: "3. Pegawai Swasta", value: "3" },
            { text: "4. Wiraswasta", value: "4" },
            { text: "5. Pegawai BUMN", value: "5" },
            { text: "6. Pensiunan", value: "6" },
            { text: "7. Lain-lain", value: "7" },
         ],
         pendapatan: [
            { text: "1. < 2.5jt", value: "1" },
            { text: "2. < 5jt", value: "2" },
            { text: "3. < 7.5jt", value: "3" },
            { text: "4. < 10jt", value: "4" },
            { text: "5. >= 10jt", value: "5" },
         ],
         pendidikan: [
            { text: "1. SD/MI", value: "1" },
            { text: "2. SMP/MTs", value: "2" },
            { text: "3. SMA Sederajat", value: "3" },
            { text: "4. D3", value: "4" },
            { text: "5. D4", value: "5" },
            { text: "6. S1", value: "6" },
            { text: "7. S2", value: "7" },
            { text: "8. S3", value: "8" },
         ],
         informasi: [
            { text: "a. Website Baiturrahman", value: "a" },
            { text: "b. Media Sosial", value: "b" },
            { text: "c. Alumni Santri Baiturrahman", value: "c" },
            { text: "d. Saudara/ Teman", value: "d" },
            { text: "e. Media Massa (Radio, Koran, dll)", value: "e" },
            { text: "f. Spanduk/Brosur", value: "f" },
            { text: "g. Road Show/ Presentasi Panitia", value: "g" },
            { text: "h. Lain-lain", value: "h" },
         ],
         motivasi: [
            { text: "a. Kemauan Sendiri", value: "a" },
            { text: "b. Kemauan Orang Tua", value: "b" },
            { text: "c. Kemauan Sendiri dan Orang Tua", value: "c" },
            { text: "d. Mengikuti Teman", value: "d" },
            { value: "e", text: "Coba-coba" },
            { value: "f", text: "Tidak Tahu" },
         ],
         kelamin: [
            { text: "Laki-laki", value: "L" },
            { text: "Perempuan", value: "P" },
         ],
         pilihanjurusan: [
            { text: "IPA (Ilmu)", value: "ipa" },
            { text: "IPS", value: "ips" },
         ],
         form: {
            nama: "",
            tempatlahir: "",
            tgllahir: "",
            nisn: "",
            nik: "",
            jeniskel: "L",
            alamat: "",
            kota: "",
            nohp: "",
            asalsekolah: "",
            pilihanjurusan: "ips",
            warganegara: "wni",
            jenjang: "P",
            tinggi: 0,
            berat: 0,
            anakke: 0,
            dari: 0,
            checked: false,
            isalumni: "0",
         },
         form2: {
            jenisayah: "kandung",
            namaayah: "",
            tmplahirayah: "",
            tgllahirayah: "",
            pekerjaanayah: "",
            penghasilanayah: "",
            pendidikanayah: "",
            alamatayah: "",
            kotaayah: "",
            jenisibu: "kandung",
            namaibu: "",
            tmplahiribu: "",
            tgllahiribu: "",
            pekerjaanibu: "",
            penghasilanibu: "",
            pendidikanibu: "",
            informasi1: "",
            informasi2: "",
            checked: false,
            informasi: "",
            motivasi: "",
         },
         dokumen: {
            pasfoto: "",
            akte: "",
            kk: "",
            ktpayah: "",
            ktpibu: "",
         },

         money: {
            decimal: ".",
            thousands: ",",
            prefix: "",
            suffix: "",
            precision: 0,
            masked: false,
         },
         show: true,
         messages: "",
         listalumni: [],
         register: {
            namalengkap: "",
            nowa: "",
            alumni: false,
            issmp: "P",
            userid: "",
            password: "",
         },
         inforegister: "",
      };
   },

   methods: {
      onsubmitregister(event) {
         event.preventDefault();
         console.log("regiseter", this.register);
         if (this.register.userid.indexOf(" ") > 0) {
            alert("User id tidak boleh ada spasi");
         } else {
            axios
               .get(this.$appUrl + "ceknohp.php?nohp=" + this.register.nowa)
               .then((response) => {
                  if (response.data.length > 0) {
                     // alert(JSON.stringify(response.data));
                     let dt = response.data[0];
                     this.inforegister =
                        "<pre>Sudah Teregister an. <b> " +
                        dt.nama +
                        "</b> No. WA/HP <b>" +
                        dt.nohp +
                        "<br /></b>Userid <b> " +
                        dt.userid +
                        "</b> Password <b>" +
                        dt.password +
                        "<br /></b><br />Terima Kasih </pre>";
                     this.showinfo();
                  } else {
                     axios
                        .post(this.$appUrl + "saveregister.php", this.register)
                        .then((response) => {
                           console.log("Sukses", response.data);
                           this.inforegister =
                              "<pre>Register berhasil  an. <b> " +
                              this.register.namalengkap +
                              "</b> No. WA/HP <b>" +
                              this.register.nowa +
                              "<br /></b>Userid <b> " +
                              this.register.userid +
                              "</b> Password <b>" +
                              this.register.password +
                              "</b><br />Silahkan untuk Login <br />Terima Kasih </pre>";
                           this.showinfo();
                        });
                     this.$bvModal.hide("modal-renewal");
                  }
               });
         }
      },

      pilihalumni(item) {
         // console.log("item alumni ", item.nama);
         this.$bvModal.hide("modal-scrollable");
         this.form.nisn = item.nis;
         if (item.nama != "None") {
            this.form.nama = item.nama;
            this.form.nisn = item.nis;
         }
         this.form.isalumni = item.nis == "None" ? "0" : "1";
      },
      getalumni() {
         // alert("get Alumni");
         // console.log("List Alumini", this.listalumni);
         if (this.listalumni.length == 0) {
            axios
               .get(this.$appUrl + "getalumni.php?jenis=header", {
                  crossDomain: true,
               })
               .then((response) => {
                  // this.listalumni.push =
                  this.listalumni = response.data;
                  const newItem = { nis: "None", nama: "None" };
                  this.listalumni.unshift(newItem);
                  console.log("List Alumini", this.listalumni);
               })
               .catch((error) => alert(error));
         }
         this.$bvModal.show("modal-scrollable");
      },
      showinfo() {
         const h = this.$createElement;
         const title = h("div", {
            domProps: { innerHTML: "INFO:" },
         });
         const isi = h("div", {
            domProps: {
               innerHTML: "<pre>" + this.inforegister + "</pre>",
            },
         });
         this.$bvModal.msgBoxOk(isi, {
            title: [title],
            size: "md",
            buttonSize: "sm",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
         });
      },

      gologin() {
         axios
            .get(
               this.$appUrl +
                  "login.php?username=" +
                  this.username +
                  "&password=" +
                  this.password +
                  "&isdaftar=" +
                  this.shownew +
                  "&smasmp=" +
                  this.selected +
                  "&nohp=" +
                  this.nohp
            )
            .then((res) => {
               //  console.log("login", res.data.includes("Duplicate"));
               if (res.data.includes("Duplicate")) {
                  this.$bvModal.msgBoxOk(
                     "Username terjadi duplikasi ... Coba username lain"
                  );
               } else if (res.data.length == 0) {
                  this.$bvModal.msgBoxOk(
                     "User tidak ditemukan / Password tidak sesuai ......."
                  );
               } else {
                  this.$globalData.userName = this.username;

                  if (this.$globalData.userName.toUpperCase() == "ADMIN") {
                     this.$globalData.IsAdmin = true;
                     this.$router.push({ path: "/admin" });
                     return;
                  }
                  this.showtabs = true;
                  this.shownew = false;
                  this.$globalData.showLogin = false;
                  this.nova = res.data[0].nova;
                  var dt = res.data[0];
                  this.$globalData.Gelombang = dt.gelombang;
                  console.log("resdata sukses login", dt);
                  // console.log("resdata sukses login", this.nova);
                  this.records = res.data[0];

                  this.status = dt.status;
                  this.issmp = res.data[0].jenjang == "P" ? true : false;
                  var no = this.nova;
                  no = String(no).substr(-3);
                  this.nopeserta = this.issmp ? "P" : "A";
                  this.nopeserta = this.nopeserta + no;
                  this.url = this.$appUrl + dt.pasfoto;
                  this.form.nama = dt.nama;
                  this.form.tempatlahir = dt.tempatlahir;
                  // alert(dt.tgllahir);
                  this.form.tgllahir = dt.tgllahir;
                  this.form.nisn = dt.nisn;
                  this.form.nik = dt.nik;
                  this.form.jeniskel = dt.jeniskel;
                  this.form.alamat = dt.alamat;
                  this.form.kota = dt.kota;
                  this.form.nohp = dt.nohp;
                  this.form.asalsekolah = dt.asalsekolah;
                  this.form.pilihanjurusan = dt.pilihanjurusan;
                  this.form.warganegara = dt.warganegara;
                  this.form.tinggi = dt.tinggi;
                  this.form.berat = dt.berat;
                  this.form.anakke = dt.anakke;
                  this.form.dari = dt.dari;
                  this.form2.jenisayah = dt.jenisayah;
                  this.form2.namaayah = dt.namaayah;
                  this.form2.tmplahirayah = dt.tmplahirayah;
                  this.form2.tgllahirayah = dt.tgllahirayah;
                  this.form2.pekerjaanayah = dt.pekerjaanayah;
                  this.form2.penghasilanayah = dt.penghasilanayah;
                  this.form2.pendidikanayah = dt.pendidikanayah;
                  this.form2.alamatayah = dt.alamatayah;
                  this.form2.kotaayah = dt.kotaayah;
                  console.log("kota ayah", this.form2.kotaayah);
                  this.form2.jenisibu = dt.jenisibu;
                  this.form2.namaibu = dt.namaibu;
                  this.form2.tmplahiribu = dt.tmplahiribu;
                  this.form2.tgllahiribu = dt.tgllahiribu;
                  this.form2.pekerjaanibu = dt.pekerjaanibu;
                  this.form2.penghasilanibu = dt.penghasilanibu;
                  this.form2.pendidikanibu = dt.pendidikanibu;
                  this.form.informasi = dt.informasi;
                  this.form.motivasi = dt.motivasi;
                  this.form.isalumni = dt.isalumni;
                  let uang = dt.isalumni == "1" ? "300,000" : "500,000";

                  this.messages =
                     "<ol><li>Jazakallah Khairan katsiran  anda  telah membuat akun  di https://newpsb.baiturrahman.com , </li><li>Silahkan melakukan Pembayaran Biaya Pendaftaran  via transfer ke Rekening Virtual Account Bank Muamalat <br /> Nomor Rekening (VA) :  <b> " +
                     this.nova +
                     "</b><br />  Tuliskan Atas nama   :<b> " +
                     this.form.nama +
                     "</b> <br /> Biaya Uang Pendaftaran gelombang ke " +
                     this.$globalData.Gelombang +
                     "  : Rp.<b> " +
                     uang +
                     " </b></li><li>Setelahss melakukan pembayaran, pendaftar menunggu approval bagian keuangan maksimal selama 1x24 JAM</li><li>Langkah selanjutnya &nbsp;<a href='https://newpsb.baiturrahman.com'>KLIK LOGIN</a>&nbsp; di newpsb.baiturrahman.com  untuk melengkapi persyaratan  </li><li>Kartu Peserta PSB bisa diakses jika sudah melakukan pembayaran</li><li>Test seleksi akan dilaksanakan pada hari <b>Ahad </b>tanggal  <b>28 April 2024 </b></li><li>Semoga Allah senantiasa memberkahi kita semua, Aamiin.. </li></ol><br>Username    : " +
                     this.username +
                     "<br>Password   : " +
                     this.password;

                  this.messages1 =
                     "Bismillahirrahmanirrahim <br><br> Assalamua'laikum wr.wb.<br>Terima kasih sudah melakukan Pendaftaran di Ponpes Baiturrahman, <br><b>Kartu Peserta PSB</b> akan aktif jika anda sudah melakukan pembayaran melalui Rekening Virtual Bank Muamalat No. <b>" +
                     this.nova +
                     " </b> (dengan mencantumkan nama peserta) sebesar Rp. <b>500,000 </b>(Lima ratus ribu rupiah) <br><br>Isilah data-data dengan benar. <br><br>Wassalamualaikum wr.wb <br>Panitia PSB";
               }

               // this.$toast.success("Login Sukses", {
               //   type: "success",
               //   position: "top-right",
               //   duration: 3000,
               //   dismissible: true,
               // });
               //  }          //   output.className = 'container';
               //   output.innerHTML = res.data;
            })
            .catch(function(err) {
               console.log("error", err);
               //   output.className = 'container text-danger';
               //   output.innerHTML = err.message;
            });
      },
      keyupMode() {},
      UniqID() {
         return (
            Date.now().toString(36) +
            Math.random()
               .toString(36)
               .substring(2) +
            new Date().getTime()
         );
      },
      onSubmit(event) {
         event.preventDefault();
         this.IsDisable = true;
         this.savedata("siswa", this.form);
         //alert(JSON.stringify(this.form));
      },
      onSubmit2(event) {
         event.preventDefault();
         this.IsDisable = true;
         this.savedata("ortu", this.form2);
         //alert(JSON.stringify(this.form));
      },
      onReset() {
         //event.prentDefault();
         // this.formshow = false;
         // // Reset our form values
         // this.form.email = "";
         // this.form.name = "";
         // this.form.food = null;
         // //this.form.checked = [];
         // // Trick to reset/clear native browser form validation state
         // this.show = false;
         // this.$nextTick(() => {
         //   this.show = true;
         // });
      },

      savedata(jenis, form) {
         axios
            .post(
               this.$appUrl +
                  "save.php?jenis=" +
                  jenis +
                  "&username=" +
                  this.$globalData.userName,
               {
                  details: form,
               },
               {
                  crossDomain: true,
                  headers: {
                     "Content-Type": "application/x-www-form-urlencoded",
                  },
               }
            )
            .then(
               (response) => {
                  this.$bvModal.msgBoxOk("Save Sukses");
                  // alert(response.data);
                  this.IsDisable = false;
                  // this.IsNew = "EDIT";
                  if (response.data == "Success 1 Success 2") {
                     //this.statusdetail = !this.statusdetail;
                     //   this.currentPage = 1;
                     //this.getData();
                  }
               },
               (error) => {
                  alert(error);
               }
            );
      },

      getData() {
         // alert("getdata");
         axios
            .get(
               this.$appUrl +
                  "getdata.php?page=" +
                  this.currentPage +
                  "&perpage=" +
                  this.perPage +
                  "&q=" +
                  this.filter +
                  "&jmlrow=" +
                  this.totalRows +
                  "&token=" +
                  this.$globalData.Token,
               {
                  crossDomain: true,
               }
            )
            .then((response) => {
               //console.log(response);
               this.keranjangs = response.data.records;
               this.totalRows = response.data.jumlah_record;
               if (this.currentPage == 1) {
                  //this.totalAllklaim = response.data.total_klaim;
                  //this.totalAllre = response.data.total_reass;
               }
               //this.currentPage = response.data.page;

               //this.status2 = false;
            })
            .catch((error) => alert(error));
         //alert(this.currentPage);
      },
      umur() {
         console.log("tgl lahir", this.form.tgllahir);
      },
      getAge(dateString) {
         var today = new Date();
         var birthDate = new Date(dateString);
         var age = today.getFullYear() - birthDate.getFullYear();
         var m = today.getMonth() - birthDate.getMonth();
         if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
         }
         return age;
      },
      addMonths(dated, months) {
         var date = new Date(dated);
         var d = date.getDate();
         date.setMonth(date.getMonth() + +months);
         if (date.getDate() != d) {
            date.setDate(0);
         }
         return date;
      },
   },

   watch: {},
   mounted() {
      var tgl = new Date();
      var tg = tgl.getDate();
      var bl = tgl.getMonth() + 1;
      var yy = tgl.getFullYear();
      var os = window.navigator.appVersion;
      this.os = os.includes("Android") ? "Android" : "Windows";
      //var yy2 = yy + 1;
      bl = bl < 10 ? "0" + bl : bl;
      tg = tg < 10 ? "0" + tg : tg;
      const awal = yy + "-" + bl + "-" + tg;
      console.log(awal);
      //this.form.akhir = yy2 + "-" + bl + "-" + tg;
      this.form.tgllahir = "1980-01-01";
      //alert(this.form.akhir);
   },
};

// 0 PNS/ASN
// 1 TNI/POLRI
// 2 Pegawai Swasta
// 3 Wiraswasta
// 4 Pegawai BUMN
// 5 Pensiunan
// 6 Lain-lain

//          <option value="0"> < Rp.2.500.000 </option>
//          <option value="1"> Rp. 2.500.001   - Rp. 5.000.000 </option>
//          <option value="2"> Rp. 5.000.001 - Rp. 7.500.000</option>
//          <option value="3"> Rp. 7.500.001 - Rp. 10.000.000</option>
//          <option value="4"> > Rp. 10.000.000 </option>

//          <option value="SD/MI"> SD/MI</option>
//           <option value="SMP/MTs"> SMP/MTs</option>
//          <option value="SMA Sederajat"> SMA Sederajat </option>
//         <option value="DIII"> DIII </option>
//          <option value="DIV"> DIV </option>
//          <option value="S1"> S1 </option>
//          <option value="S2"> S2</option>
//          <option value="S3"> S3</option>

// pasfoto
// akte  pdf
// kk pdf
// ktp ayah
// ktp ibu
// ket sehat
</script>

<style>
/* mulai disini  */
.inner-addon {
   position: relative;
}

/* style icon */
.inner-addon .bi {
   position: absolute;
   padding: 10px;
   margin-top: -2px;
   margin-left: -2px;
   pointer-events: none;
}

/* align icon */
.left-addon .bi {
   left: 0px;
}

/* add padding  */
.left-addon input {
   padding-left: 40px;
}
.login-logo a {
   color: #fff;
   text-shadow: 1px 0px 6px rgba(202, 202, 202, 0.8),
      -2px -2px 2px hsla(0, 0%, 30%, 0.5);
}
</style>
