import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home.vue'
import Brosur from '../components/brosur.vue'
import Admin from '../components/admin.vue'
import Register from '../components/register.vue'

// import Foods from '../views/Foods.vue'
// import FoodDetail from '../views/FoodDetail.vue'
// import Keranjang from '../views/Keranjang.vue'
// import PesananSukses from '../views/PesananSukses.vue'
// import H2H from '../views/h2h.vue'
// import H2Hpagination from '../views/h2h-pagination.vue'
// import Invoice from '../views/invoice.vue'
// import RegKlaimTeknik from "@/views/regklaimteknik.vue";


Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/brosur',
		name: 'Brosur',
		component: Brosur
	},
	{
		path: '/register',
		name: 'Register',
		component: Register
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin
	},

	//   {
	//     path: '/about',
	//     name: 'About',
	//     // route level code-splitting
	//     // this generates a separate chunk (about.[hash].js) for this route
	//     // which is lazy-loaded when the route is visited.
	//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	//   }
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	// base: process.env.NODE_ENV === 'development' ? '/' : '/psb.new/',
	// base: process.env.NODE_ENV === 'development' ? '/' : process.env.BASE_URL + '/temp/',
	routes
})

export default router
