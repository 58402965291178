<template>
   <div class="container" style="height:500px">
      <div
         v-print="'#printme'"
         style="cursor: pointer;"
         align="left"
         v-show="false"
      >
         <b-icon-printer class="ml-4" mr-1 />Print
      </div>
      <!-- <div class="container" style="height:420px" id="printme"> -->
      <div id="printme" style="height:auto" v-show="false">
         <div class="fixed">
            <img
               src="https://newpsb.baiturrahman.com/dokumen/template-kartu.jpg"
               width="465px"
               height="344px"
            />
            <div style="position:absolute; left:60px; top:76px ">
               <!-- <div style="margin-left:-220px; margin-top:-270px;  "> -->
               <!-- <div> -->
               <img :src="url" width="120px" height="150px" />
               <p class="mt-4 mb-0 ml-0">
                  <b>{{ nama }} </b>
               </p>
               <p class="mt-1">
                  <b>No Peserta : {{ nopeserta }}</b>
               </p>
            </div>
         </div>
      </div>
      <div v-print="'#myprint'" style="cursor: pointer;" align="left">
         <b-icon-printer class="ml-4" mr-1 />Print
      </div>
      <div class="row ">
         <div id="myprint">
            <img
               src="https://newpsb.baiturrahman.com/dokumen/template-kartu.jpg"
               width="572px"
               height="444px"
            />
            <div
               class="bgx"
               style="margin-top: -351px; margin-left: 75px; position: absolute;"
            >
               <img :src="url" width="153px" height="199px" />
               <p class="mt-4 mb-0">
                  <b>{{ nama }} </b>
               </p>
               <p class="mt-1">
                  <b>No Peserta : {{ nopeserta }}</b>
               </p>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "Kartu",
   props: ["nama", "nopeserta", "url"],
};
</script>
<style>
.bgimg {
   background: url("http://localhost:8800/psb/dokumen/template-kartu-2.jpg");
}

.fixed {
   position: absolute;
   left: 10;
   top: 140px;
   width: 480px;
   height: 364px;
   border: 3px solid #73ad21;
}
.Images {
   flex-grow: 1;
   overflow-y: hidden;
}
.bg {
   /* The image used */
   width: 570px !important;
   height: 440px !important;
   background-image: url("https://newpsb.baiturrahman.com/dokumen/template-kartu.jpg");
   background-size: 100% 100% !important;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}
</style>
