<template>
   <div class="container-fluid ">
      <!-- <b-form-group label="Inline radios (default)" class="text-left"> -->
      <b-table :items="summary" small class="col-md-4"> </b-table>
      <div class="card shadow" style="height: auto ">
         <b-tabs
            card
            shadow
            active-nav-item-class="font-weight-bold text-uppercase"
            active-tab-class="text"
         >
            <b-tab title="Set Pembayaran PSB" active class="tab primary">
               <div class="row">
                  <b-button @click="getdata(1)" size="sm">Reresh </b-button>
                  <div
                     v-print="'#printme1'"
                     style="cursor: pointer;"
                     align="left"
                  >
                     <b-icon-printer class="ml-4" mr-1 />Print
                  </div>
               </div>
               <div id="printme1">
                  <b-table
                     :items="rows"
                     small
                     responsive
                     style="font-size='13px'"
                  >
                     <template #cell(no)="row"> {{ row.index + 1 }} </template>>
                     <template #cell(aprove)="row">
                        <!-- {{ row }} -->
                        <b-button
                           size="sm"
                           @click="
                              approve(
                                 row.item.aprove,
                                 row.item.username,
                                 row.index
                              )
                           "
                           class="mr-1"
                           align="left"
                        >
                           {{ row.item.aprove == 1 ? "Approve" : "UnApprove" }}
                        </b-button>
                     </template>
                  </b-table>
               </div>
            </b-tab>
            <b-tab title="Laporan-laporan" class="tab primary">
               <div class="row">
                  <b-button @click="getdata(2)" size="sm">Reresh </b-button>
                  <div
                     v-print="'#printme2'"
                     style="cursor: pointer;"
                     align="left"
                  >
                     <b-icon-printer class="ml-4" mr-1 />Print
                  </div>
               </div>

               <div id="printme2">
                  <b-table
                     :items="rows2"
                     small
                     responsive
                     style="font-size='13px'"
                  >
                     <template #cell(no)="row"> {{ row.index + 1 }} </template>>
                     <template #cell(pasfoto)="row">
                        <a :href="$appUrl + row.item.pasfoto" target="_blank">
                           <div v-show="row.item.pasfoto != null">
                              <b-icon-image />
                           </div>
                           <div v-show="row.item.pasfoto == null">
                              <b-icon-file-excel />
                           </div>
                        </a>
                     </template>
                     <template #cell(akte)="row">
                        <a :href="$appUrl + row.item.akte" target="_blank">
                           <div v-show="row.item.akte != null">
                              <b-icon-image />
                           </div>
                           <div v-show="row.item.akte == null">
                              <b-icon-file-excel />
                           </div>
                        </a>
                     </template>
                     <template #cell(kk)="row">
                        <a :href="$appUrl + row.item.kk" target="_blank">
                           <div v-show="row.item.kk != null">
                              <b-icon-image />
                           </div>
                           <div v-show="row.item.kk == null">
                              <b-icon-file-excel />
                           </div>
                        </a>
                     </template>
                     <template #cell(ktpa)="row">
                        <a :href="$appUrl + row.item.ktpa" target="_blank">
                           <div v-show="row.item.ktpa != null">
                              <b-icon-image />
                           </div>
                           <div v-show="row.item.ktpa == null">
                              <b-icon-file-excel />
                           </div>
                        </a>
                     </template>
                     <template #cell(ktpi)="row">
                        <a :href="$appUrl + row.item.ktpi" target="_blank">
                           <div v-show="row.item.ktpi != null">
                              <b-icon-image />
                           </div>
                           <div v-show="row.item.ktpi == null">
                              <b-icon-file-excel />
                           </div>
                        </a>
                     </template>
                     <template #cell(ketsehat)="row">
                        <a :href="$appUrl + row.item.ketsehat" target="_blank">
                           <div v-show="row.item.ketsehat != null">
                              <b-icon-image />
                           </div>
                           <div v-show="row.item.ketsehat == null">
                              <b-icon-file-excel />
                           </div>
                        </a>
                     </template>
                  </b-table>
               </div>
            </b-tab>
         </b-tabs>
      </div>
   </div>
</template>

<script>
import axios from "axios";

export default {
   name: "Admin",
   components: {},
   data() {
      return {
         icon1: "<b-icon-image />",
         icon2: "<b-icon-file-excel />",
         rows: [],
         rows2: [],
         summary: [],
      };
   },
   mounted() {
      this.getdata(1);
      this.getdata(2);
   },
   methods: {
      approve(status, user, index) {
         axios
            .get(
               this.$appUrl +
                  "getdata.php?jenis=approve&status=" +
                  status +
                  "&userid=" +
                  user
            )
            .then((res) => {
               console.log("disini");
               console.log("va ", res.data);
               //  if (res.data != "approve") {
               //    this.rows = res.data;
               //    console.log("va ", this.rows);
               //  }
               this.rows[index]["aprove"] = status == "1" ? "2" : "1";
            })
            .catch(function(err) {
               console.log("error", err);
               //   output.className = 'container text-danger';
               //   output.innerHTML = err.message;
            });

         // alert(status + "," + user);
      },
      getdata(jn) {
         axios
            .get(this.$appUrl + "getdata.php?jenis=" + jn)
            .then((res) => {
               if (jn == 1) {
                  this.rows = res.data.data;
               } else {
                  this.rows2 = res.data.data;
               }
               this.summary = res.data.summary;
               console.log("disini");
            })
            .catch(function(err) {
               console.log("error", err);
               //   output.className = 'container text-danger';
               //   output.innerHTML = err.message;
            });
      },
   },
};
</script>

<style>
#app_ {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: #2c3e50;
   margin-top: 60px;
}
#stickys_ {
   background-color: rgb(251, 253, 251);
   position: fixed;
   z-index: 99;
   width: 100%;
   margin-top: 6px;
}
</style>
