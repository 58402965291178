import Vue from 'vue'
import App from './App.vue'

import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//import './assets/css/main.css'

import VueToast from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-default.css';

import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import Print from 'vue-print-nb'


import money from 'v-money'
import moment from 'moment'

// register directive v-money and component <money>
Vue.use(money, { precision: 0 })
Vue.use(VueToast);
Vue.use(VueDatePicker);
Vue.use(Print);

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


//import Dialog from 'bootstrap-vue-dialog'
//Vue.use(Dialog)
// Vue.use(Dialog, {
//   context,
//   property
// })


// define variabel global for USE

if (process.env.NODE_ENV === 'development') {
	// Vue.prototype.$appUrl = 'http://192.168.101.20:8800/psb/';
	Vue.prototype.$appUrl = 'http://localhost:8800/psb/';
} else {
	Vue.prototype.$appUrl = 'php.script/';
}

Vue.prototype.$globalData = Vue.observable({
	userName: "",
	TokenOK: 0,
	showLogin: true,
	IsAdmin: false,
	Gelombang: '3',
	showmenu: true,
	Token: localStorage.getItem("mytoken")
})


Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')

Vue.filter('formatDate', function (value) {
	if (value) {
		return moment(String(value)).format('DD-MMM-yyyy')
		// dipanggil {{ response.create_at | formatDate }}
	}
});