<template>
   <div class="login-box  col-3 ">
      <div class="login-logo">
         <a href="javascript:void(0)"><b>Registrasi</b> Akun</a>
      </div>
      <b-link v-b-modal.modal-renewal class="mt-4">Renewal Notice</b-link>
      <!-- /.login-logo -->
      <b-modal
         id="modal-renewal"
         size="md"
         :ok-only="true"
         title="Register View"
         scroll
      >
         <div class="login-box-body text-left">
            <center>
               <img
                  width="120px"
                  src="https://newpsb.baiturrahman.com/img/logo-psb.f811bf93.jpg"
               />
            </center>
            <br />
            <form method="post">
               <b-input-group class="mt-3" size="sm">
                  <template #append class="bg-white border-left-0 ">
                     <b-input-group-text class="bg-transparent "
                        ><b-icon-trash
                     /></b-input-group-text>
                  </template>
                  <b-form-input class="border-right-0 "></b-form-input>
               </b-input-group>
               <div class="mb-2 position-relative">
                  <input
                     type="text"
                     size="sm"
                     name="tanggal_surat"
                     id="tanggal_surat"
                     class="form-control"
                     v-model="tanggalSurat"
                     data-toggle="tooltip"
                     title="Masukkan tanggal surat."
                     placeholder="Masukkan alasan mengikuti ini"
                  />
                  <b-icon-calendar class="icon"></b-icon-calendar>
               </div>

               <div class="form-group has-feedback">
                  <input
                     type="text"
                     name="fullname"
                     class="form-control register"
                     placeholder="Nama Lengkap"
                  />
                  <span
                     class="glyphicon glyphicon-erase form-control-feedback"
                  />
               </div>
               <div class="form-group has-feedback">
                  <input
                     type="text"
                     name="phone"
                     class="form-control register"
                     placeholder="No WA"
                  />
                  <span
                     class="glyphicon glyphicon-phone form-control-feedback"
                  ></span>
               </div>
               <div class="form-group has-feedback">
                  <input
                     type="text"
                     name="email"
                     class="form-control register"
                     placeholder="Alamat Email"
                  />
                  <span
                     class="glyphicon glyphicon-envelope form-control-feedback"
                  ></span>
               </div>
               <div class="form-group has-feedback form-checkbox-alumni">
                  <input
                     type="radio"
                     name="is_alumni"
                     class="minimal register"
                     value="1"
                  />
                  Alumni Baiturrahman<br />
               </div>
               <div class="form-group has-feedback">
                  <input
                     type="text"
                     name="user_name"
                     class="form-control register"
                     placeholder="Username"
                  />
                  <span
                     class="glyphicon glyphicon-user form-control-feedback"
                  ></span>
                  <em>*Tidak mengandung sepasi</em>
               </div>
               <div class="form-group has-feedback">
                  <input
                     type="password"
                     name="pass"
                     class="form-control register"
                     maxlength="8"
                     placeholder="Password"
                  />
                  <span
                     class="glyphicon glyphicon-lock form-control-feedback"
                  ></span>
                  <em>*Maksimal 8 karakter</em>
               </div>
               <div class="form-group has-feedback">
                  <input
                     type="password"
                     name="repass"
                     class="form-control register"
                     maxlength="8"
                     placeholder="Ulangi Password"
                  />
                  <span
                     class="glyphicon glyphicon-log-in form-control-feedback"
                  ></span>
               </div>
               <div class="row" id="before-notif">
                  <!-- /.col -->
                  <div class="col-xs-12">
                     <button
                        type="button"
                        class="btn btn-primary btn-block btn-flat"
                        data-toggle="modal"
                        data-target="#modal-preview"
                        id="btn-lanjutkan"
                     >
                        Lanjutkan
                     </button>
                  </div>
                  <!-- /.col -->
               </div>
            </form>
         </div>
      </b-modal>
   </div>
</template>
<script>
export default {
   name: "Register",
   components: {},
   mounted() {
      this.$globalData.showmenu = false;
      this.$bvModal.show("modal-renewal");
   },
};
</script>
<style>
.login-box {
   margin: 3% auto;
}

.login-box-body {
   border-radius: 8px;
   padding-top: 35px;
   padding-bottom: 30px;
}

.login-page {
   background: url("https://newpsb.baiturrahman.com/img/psb.47f441fc.jpg");
   /* no-repeat fixed 100% 100% / cover rgba(0, 0, 225, 7); */
}

.color-overlay {
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   position: fixed;
   background: black;
   opacity: 0.4;
   z-index: -50;
}

.login-logo a {
   color: #743e77a1;
   text-shadow: 1px 0px 6px rgba(202, 202, 202, 0.8),
      -2px -2px 2px hsla(0, 0%, 30%, 0.5);
}
span {
   position: absolute;
   margin-left: 5px;
   height: 25px;
   display: flex;
   align-items: center;
}
input {
   padding-left: 25px;
   height: 20px;
}
.icon {
   position: absolute;
   top: 0.35rem;
   right: 1rem;
   bottom: 0;
   padding: 0.375rem 0.75rem;
   pointer-events: none;
}
</style>
